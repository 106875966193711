import { graphql, useStaticQuery } from "gatsby";
import { Footer, Box, Text } from "grommet";
import { Home } from "grommet-icons";
import React from "react";

export default function Contact() {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query FooterQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "footer" } } }
        ) {
          edges {
            node {
              html
            }
          }
        }
      }
    `
  );

  return (
    <>
      <Footer background="dark-1" pad="large">
        <Text
          id="kontakt"
          dangerouslySetInnerHTML={{
            __html: allMarkdownRemark.edges[0].node.html,
          }}
        />
      </Footer>

      <Footer
        background="dark-2"
        pad={{ horizontal: "large", vertical: "small" }}
      >
        <Box direction="row" gap="small">
          <Text alignSelf="center">
            Aleksandra Kostuch Kancelaria Radcy Prawnego
          </Text>
        </Box>
        <Text textAlign="center" size="small">
          © 2021 Copyright Phren Software Jaroslaw Konik
        </Text>
      </Footer>
    </>
  );
}
